import { defineComponent } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  components: {
    SvgIcon: SvgIcon
  },
  props: {
    links: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  }
});