import { defineComponent } from 'vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import ServiceContent from '@/components/service/content.vue';
export default defineComponent({
  name: 'ServiceMobile',
  components: {
    Breadcrumb: Breadcrumb,
    ServiceContent: ServiceContent
  },
  inject: ['isMobile']
});