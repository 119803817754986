import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-33a6746a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "breadcrumb"
};
var _hoisted_2 = {
  key: 1,
  class: "link"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");

  var _component_svg_icon = _resolveComponent("svg-icon");

  return _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, function (_ref, i) {
    var path = _ref.path,
        name = _ref.name;
    return _openBlock(), _createElementBlock("li", {
      class: "path",
      key: i
    }, [path ? (_openBlock(), _createBlock(_component_router_link, {
      key: 0,
      class: "link linkable",
      to: {
        path: path
      }
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(name), 1)];
      }),
      _: 2
    }, 1032, ["to"])) : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(name), 1)), _ctx.links[i + 1] ? (_openBlock(), _createBlock(_component_svg_icon, {
      key: 2,
      class: "arrow",
      iconName: "arrow-breadcrumb"
    })) : _createCommentVNode("", true)]);
  }), 128))], 512)), [[_vShow, _ctx.links.length > 0]]);
}